<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return{
            rutas: [
                { titulo: 'Flujo de general de dinero', ruta: 'admin.tesoreria.flujo-general', can: 'tab2_tesoreria_flujo_dinero_general' },
                { titulo: 'Cajas', ruta: 'admin.tesoreria.cajas', can: 'tab2_tesoreria_flujo_dinero_cajas' },
                { titulo: 'Movimientos de cajas', ruta: 'admin.tesoreria.movimientos-cajas', can: 'tab2_tesoreria_flujo_dinero_movimientos' },
                { titulo: 'Cuentas bancarias', ruta: 'admin.tesoreria.cuentas-bancarias', can: 'tab2_tesoreria_flujo_dinero_cuentas' },
            ]
        }
    }
}
</script>
